<template>
  <div>
    <TopNavigation />
    <PageHeader
      imageUrl="img_hdr0"
      titleWord1="UPLIFT"
      titleWord2="BOULDER"
      description="Find your potential in in our group classes, specialty programs and personal training."
    />
    <AdVideoView />
    
    <GalleryAttributes />
    <MembershipTypes />
    <InstagramBanner />
    <div>
      <WallaSchedule
        walla-id="f918ab39-eff5-4148-ac41-4a7a243735b6"
        location-id="6367"
      />
    </div>
    <TestimonialsBanner />
    <LaunchBanner />
    <button @click="scrollToTop" class="scroll-to-top">
      <b-icon-arrow-up />
    </button>
    <FooterComp />
  </div>
</template>

<script>
// @ is an alias to /src
import TopNavigation from "@/components/TopNavigation.vue";
import PageHeader from "@/components/PageHeader.vue";
import FooterComp from "@/components/FooterComp.vue";
import TestimonialsBanner from "@/components/TestimonialsBanner.vue";
import MembershipTypes from "@/components/MembershipTypes.vue";
import InstagramBanner from "@/components/InstagramBanner.vue";
import LaunchBanner from "@/components/LaunchBanner.vue";
import AdVideoView from "@/components/AdVideo.vue";
import WallaSchedule from "@/components/WallaSchedule.vue";
import GalleryAttributes from "@/components/GalleryAttributes.vue";

export default {
  name: "HomeView",
  components: {
    TopNavigation,
    PageHeader,
    FooterComp,
    TestimonialsBanner,
    MembershipTypes,
    InstagramBanner,
    WallaSchedule,
    LaunchBanner,
    AdVideoView,
    GalleryAttributes,
  },
  data() {
    return {
      //modalOnLoad: false,
    };
  },
  methods: {},
  metaInfo() {
    return {
      title: "Uplift Boulder Gym",
      meta: [
        {
          name: "description",
          content:
            "Small strength training gym in Boulder, Colorado. We would love to show you how strong and capable you can be.",
        },
        {
          property: "og:title",
          content:
            "Uplift - Find your potential in our strength based group classes, personal training or open gym",
        },
        { property: "og:site_name", content: "Uplift" },
        { property: "og:type", content: "website" },
        { name: "robots", content: "index,follow" },
      ],
    };
  },
};
</script>

<style  lang="scss">
.row {
  margin: 10px, auto, 10px, auto;
}
.topRow {
  margin-bottom: 20px;
}
.quadCol {
  max-width: 500px;
}
.btn {
  font-family: "Anton" !important;
  font-style: italic !important;
  font-size: 2rem !important;
  color: black !important;
  min-height: 48px !important;
  // min-width: 90px !important;
  max-width: 180px !important;
  border-radius: 15px !important;
  background-color: #8eb6c7 !important;
  border: 2px solid rgb(1, 99, 99) !important;
}
.btn:hover {
  background-color: rgb(1, 99, 99) !important;
}

.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 10px;
  background-color: #d8e030 !important;
  color: #000;
  border: none;
  border-radius: 5px;
  max-width: 60px !important;
  max-height: 60px !important;
  cursor: pointer;
}

#backtotop {
  display: none; /* Hidden by default */
  position: fixed; /* Fixed/sticky position */
  bottom: 20px; /* Place the button at the bottom of the page */
  right: 30px; /* Place the button 30px from the right */
  z-index: 99; /* Make sure it does not overlap */
  border: none; /* Remove borders */
  outline: none; /* Remove outline */

  cursor: pointer; /* Add a mouse pointer on hover */
  padding: 15px; /* Some padding */
  border-radius: 10px; /* Rounded corners */
  font-size: 18px; /* Increase font size */

  background-color: #d8e030 !important;
  max-width: 60px !important;
  max-height: 60px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.ttl {
  font-family: "Anton";
  font-size: 4rem;
  text-align: center;
  font-style: italic;
}
.jumboLight {
  padding-top: 20px;
  padding-bottom: 10px;
  background-color: white;
  color: black;
}
.jumboDark {
  padding-top: 20px;
  padding-bottom: 10px;
  background-color: black;
  color: white;
  border-style: none;
  margin-top: -5px;
}
.lineBreak {
  margin: 10px 0 30px 0;
  padding: 0;
  height: 5px;
  background-color: #d8e030;
}
.imgGallery {
  width: 100%;
}
.galleryBk {
  align-items: center;
  justify-content: center;
  display: flex;
}
.galleryTxt {
  color: white;
  font-size: 24px;
  font-family: "Montserrat";
  margin: auto;
}
.lineDiag {
  height: 10px;
  background-color: darkcyan;
  transform: skewY(-20deg);
}
.dramaP {
  font-size: 1.5rem;
  font-variant-caps: all-small-caps;
}
.cardDark {
  background-color: black !important;
  color: white !important;
  min-height: 250px;
}
.cardLight {
  background-color: white !important;
  color: black !important;
  min-height: 250px;
  border-width: 0 !important;
}
.card-title {
  font-size: 3rem;
  font-weight: bold;
  font-variant-caps: all-small-caps;
}

.resImg {
  width: 100%;
  //max-height: 400px;
  height: auto;
  max-width: 1200px;
  box-shadow: 20px #8eb6c7 inset;
}
.home_img {
  background-image: url("../assets/img_hdr0.png");
}
.main_header {
  //background-image: url('../assets/230717, Home.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  height: 400px;
  box-shadow: inset 11px -1px 16px 20px #000000cf;
}
.homeDivBlock {
  position: relative;
  background-color: #d8e030;
  height: 80px;
  width: 80px;
}
.homeDivText {
  position: absolute;
  background-color: white;
  margin: 10px auto 10px 10px;
  width: 90%;
  max-width: 600px;

  text-align: left;
  color: black;
  padding: 10px 10px;
}
.launchSignUpBtn {
  width: 180px;
  min-height: 48px;
  border: 2px solid #8eb6c7;
  background-color: magenta;
  border-radius: 15px;
  align-items: center;
  display: flex;
  margin: 20px auto;
  justify-content: center;

  :hover {
    background: #8eb6c7;
  }
}
.launchSignUpTxt {
  text-decoration: none;
  color: black;
  font-weight: bold;
  font-size: 14pt;
  :hover {
    color: #8eb6c7;
    background: none;
  }
}

// for mobile displays
@media (max-width: 768px) {
  .topRow {
    margin-bottom: auto;
  }
  .quadCol {
    margin-bottom: 5px;
  }
  .cardLight {
    margin-bottom: 5px;
  }

  .ttl {
    font-size: 3.5rem;
  }
}
.home_header {
  box-shadow: inset 1px -1px 10px 10px #000000cf;
}
@media (max-width: 400px) {
  .galleryTxt {
    font-size: 16px;
  }
}
</style>