<template>
    <div>
        <b-jumbotron>
            <b-row class="justify-content-center" style="margin-top:60px;">
                <b-col cols="7" md="4"> 
                    <h1 class="ttl"> COACHES </h1>
                    <div class="lineBreak" style="margin-top: -10px;"></div>
                </b-col>
            </b-row>
            <b-row class="justify-content-center " >
                <b-col cols="10" md="5" style="margin-top:60px;" >
                    <h2 class="coach_name"> JENA </h2>
                    <img  class="coach_img" src="../assets/jena.png"  >
                    <div class="instaHandle">
                        <a href="https://www.instagram.com/jenarationfitness/" target="_blank">
                            <img  src="../assets/instagramGlyph.png"  style="width: 40px; height: auto;">
                        </a>
                        <p class="instaHandleText"> @jenarationfitness </p>
                    </div>
                    <p  class="coach_desc" style="padding-top: 10px;">
                        Jena has a decade of experience under her belt working 1-1 with clients and small group classes. 
                        Her coaching approach emphasizes helping folks uncover their capabilities and gain confidence in 
                        their body's abilities. She thrives working with former athletes and those recovering from injury 
                        but truly enjoys working with any client who is eager to learn. She moved to Colorado to escape the 
                        brutally cold Minnesota winters and found herself working at Bella Strength, then Town Strength Boulder 
                        and now Uplift Boulder!
                    </p>
                    <p class="coach_certs" ><b>Certifications</b></p>
                    <ul style="display: inline-block; 	text-align: left; ">
                        <li>M.S. Educational Leadership for Sports Management - Winona State University   </li>
                        <li>B.S. Exercise Science - Winona State University </li>
                        <li>Certified Personal Trainer - NSCA  </li>
                        <li>Viking Ninja Warrior Level 1</li>
                    </ul>
                </b-col>
                <b-col cols="10" md="5"  style="margin-top:60px;">
                    <h2 class="coach_name"> DEVIN </h2>
                    <img  class="coach_img" src="../assets/devin.png"  >
                    <div class="instaHandle">
                        <a href="https://www.instagram.com/devin_strength/" target="_blank">
                            <img  src="../assets/instagramGlyph.png"  style="width: 40px; height: auto;">
                        </a>
                        <p class="instaHandleText">@devin_strength </p>
                    </div>
                    <p  class="coach_desc" style="padding-top: 10px;">
                        Born and raised in Colorado, Devin has a passion for strength and learning. 
                        He strives to empower clients with customized tools and principles to enhance their health and 
                        well being, while expanding their perception of the body’s amazing capacity for change. 
                        Having trained hundreds of people over tens of thousands of workouts since 2010, Devin wants 
                        to show as many people as possible how capable their lives can be through the pursuit of strength. 
                    </p>
                    <p class="coach_certs" ><b>Certifications</b></p>
                    <ul style="display: inline-block; 	text-align: left; ">
                        <li>B.A. Integrative Physiology - CU Boulder  </li>
                        <li>Certified Strength and Conditioning Specialist -  NSCA </li>
                        <li>Certified Special Populations Specialist - NSCA </li>
                        <li>USA Weightlifting Level 1 Coach</li>
                        <li>USA Powerlifting Level 1 Coach</li>
                        <li>Precision Nutrition Level 1 Coach</li>
                    </ul>
                </b-col>

            </b-row>
            <b-row class="justify-content-center " >
                <b-col cols="10" md="5"  style="margin-top:60px;">
                    <h2 class="coach_name"> ALISSA </h2>
                    <img  class="coach_img" src="../assets/alissa.png"  >
                    <div class="instaHandle">
                        <a href="https://www.instagram.com/adgstrength/" target="_blank">
                            <img  src="../assets/instagramGlyph.png"  style="width: 40px; height: auto;">
                        </a>
                        <p class="instaHandleText"> @adgstrength </p>
                    </div>
                    <p  class="coach_desc" style="padding-top: 10px;">
                        Alissa has been a personal trainer and group fitness coach for ~5 years, with over 1,500 
                        group classes taught under her belt. With a background of 15 years of dancing, she views 
                        mobility and flexibility as a key component to strength & functional training. As well as the 
                        importance of learning body awareness whether it's in the gym or in their daily life.
                    </p>
                    <p class="coach_certs" ><b>Certifications</b></p>
                    <ul style="display: inline-block; 	text-align: left; ">
                        <li>Certified Personal Trainer - NASM   </li>
                        <li>Corrective Exercise Specialist - NASM  </li>
                    </ul>
                    <div>
                        <b-btn class="btn_coach" 
                            href="https://docs.google.com/forms/d/e/1FAIpQLSf8wYeZGAYPBliswk6zCuOvvfWvMquMRa4b7LbrpRRrAsiHiQ/viewform?usp=sf_link"
                            target="_blank"
                            >Contact Coach Alissa</b-btn>
                    </div>
                </b-col>
                <b-col cols="10" md="5"  style="margin-top:60px;">
                    <h2 class="coach_name"> MITCH </h2>
                    <img  class="coach_img" src="../assets/mitch.png"  >
                    <div class="instaHandle">
                        <a href="https://www.instagram.com/mitch_hash/" target="_blank">
                            <img  src="../assets/instagramGlyph.png"  style="width: 40px; height: auto;">
                        </a>
                        <p class="instaHandleText">@mitch_hash </p>
                    </div>
                    <p  class="coach_desc" style="padding-top: 10px;">
                        Mitch is passionate about showing individuals their capacity for change and growth no matter the starting place. 
                        After struggling with back pain and mobility issues, he’s had to dig deep into the corrective and 
                        technical aspects of training the body and humbly go back to the basics in order to proceed forward. 
                        He currently trains for rock climbing, hiking in the mountains, and feeling good and joyful on the daily!
                    </p>
                    <p class="coach_certs" ><b>Certifications</b></p>
                    <ul style="display: inline-block; 	text-align: left; ">
                        <li>Certified Personal Trainer - ACSM </li>
                        <li>FRC Mobility Specialist </li>

                    </ul>
                    <div>
                        <b-btn class="btn_coach" 
                            href="https://docs.google.com/forms/d/e/1FAIpQLScUDGAtREv9I6ADKVjCOEh4VCTjjaztxYIlUDL3Z52NdN9K9w/viewform?usp=sf_link"
                            target="_blank"
                            >Contact Coach Mitch</b-btn>
                    </div>
                </b-col>

            </b-row>
            <b-row class="justify-content-center " >
                <b-col cols="10" md="5" style="margin-top:60px;">
                    <h2 class="coach_name"> CAMERON </h2>
                    <img  class="coach_img" src="../assets/cam.png"  >
                    <div class="instaHandle">
                        <a href="https://www.instagram.com/cameronsparksfitness/" target="_blank">
                            <img  src="../assets/instagramGlyph.png"  style="width: 40px; height: auto;">
                        </a>
                        <p class="instaHandleText"> @cameronsparksfitness  </p>
                    </div>
                    <p  class="coach_desc" style="padding-top: 10px;">
                        Cameron first found his interest in fitness and exercise after losing 60 lbs 
                        going into his Freshman year of high school and beginning to workout at his local YMCA. 
                        He is a recent graduate of Fitchburg State University - a college in Massachusetts - 
                        where he obtained his Exercise and Sports Science degree with a concentration in 
                        Fitness Management. Cameron loves being outdoors, enjoying good food, and is a 
                        huge proponent of mindfulness and the continuous pursuit of learning, self-improvement, 
                        and showing up for oneself! 
                    </p>
                    <p class="coach_certs" ><b>Certifications</b></p>
                    <ul style="display: inline-block; 	text-align: left; ">
                        <li>Certified Fitness Nutrition Specialist - ACE   </li>
                        <li>Certified Personal Trainer - ACE</li>
                        <li>B.S. Exercise and Sports Science  - Fitchburg State University</li>
                    </ul>
                    <div>
                        <b-btn class="btn_coach" 
                            href="https://docs.google.com/forms/d/e/1FAIpQLSf-DZUjQ9ozfo29oBXPnXaAF88Uto3OCBEtRok_43CjoJ2LTQ/viewform?usp=sf_link"
                            target="_blank"
                            >Contact Coach Cameron</b-btn>
                    </div>
                </b-col>
                <b-col cols="10" md="5"  style="margin-top:60px;">
                    <h2 class="coach_name"> LIZZIE </h2>
                    <img  class="coach_img" src="../assets/lizzie.png"  >
                    <div class="instaHandle">
                        <a href="https://www.instagram.com/coachlizzie_rd/" target="_blank">
                            <img  src="../assets/instagramGlyph.png"  style="width: 40px; height: auto;">
                        </a>
                        <p class="instaHandleText"> @coachlizzie_rd  </p>
                    </div>
                    <p  class="coach_desc" style="padding-top: 10px;">
                        Lizzie is a personal trainer and registered dietitian with degrees in nutrition and exercise physiology! 
                        Lizzie loves helping people achieve their health and fitness goals through sustainable, fun, and effective training. 
                        She believes having a community of like-minded individuals to support and uplift (pun intended) you along the way 
                        is incredibly powerful. When not in the gym, you can catch Lizzie pursuing her love for outdoor sports 
                        including trail & mountain running, climbing, skiing, etc. She is a competitive ultrarunner, dog mom, 
                        coffee shop connoisseur, and science nerd!
                    </p>
                    <p class="coach_certs" ><b>Certifications</b></p>
                    <ul style="display: inline-block; 	text-align: left; ">
                        <li>Certified Personal Trainer - CSCS </li>
                        <li>Registered Dietician </li>
                    </ul>
                    <div>
                        <b-btn class="btn_coach" 
                            href="https://docs.google.com/forms/d/e/1FAIpQLSfEH-2N5O-p8jqCvVRXJXHJ5neRUneAKxFcS8iQlBrVnYlt9Q/viewform?usp=sf_link"
                            target="_blank"
                            >Contact Coach Lizzie</b-btn>
                    </div>
                </b-col>

            </b-row>
            <b-row class="justify-content-center " style="margin-bottom: 120px;">
                <b-col cols="10" md="5"  style="margin-top:60px;">
                    <h2 class="coach_name"> ROSS </h2>
                    <img  class="coach_img" src="../assets/ross.png"  >
                    
                    <p  class="coach_desc" style="padding-top: 10px;">
                        Ross’s passions include trail running, hiking, and being outdoors with his dog. 
                        As a runner at heart, he brings a wealth of endurance and aerobic experience 
                        to the table. Ross has been a student of many gyms and genres of fitness over 
                        the years and is thrilled to go from trainee to trainer. In New York, he was a 
                        marathon runner and since moving to Colorado has begun ultra-marathoning. With 
                        a chronic condition for helping people any way he can, this trainer is thrilled 
                        to join the Uplift team and add energy to this community 
                    </p>
                    <p class="coach_certs" ><b>Certifications</b></p>
                    <ul style="display: inline-block; 	text-align: left; ">
                        <li>Certified Personal Trainer - NASM</li>
                    </ul>
                    <div>
                        <b-btn class="btn_coach" 
                            href="https://docs.google.com/forms/d/e/1FAIpQLSc-pCoLRlNYWoCSTJXa2CYZJdeYz0im2cxqLE-1HVlDH7bftg/viewform?usp=sf_link"
                            target="_blank"
                            >Contact Coach Ross</b-btn>
                    </div>
                </b-col>
                <b-col cols="10" md="5"  style="margin-top:60px;">
                    <h2 class="coach_name"> Cassidy </h2>
                    <img  class="coach_img" src="../assets/cassidy.png"  >
                    <div class="instaHandle">
                        <a href="https://www.instagram.com/coachcassjoy_" target="_blank">
                            <img  src="../assets/instagramGlyph.png"  style="width: 40px; height: auto;">
                        </a>
                        <p class="instaHandleText"> @coachcassjoy_  </p>
                    </div>
                    <p  class="coach_desc" style="padding-top: 10px;">
                        Cassidy Noeldner is a Colorado native with her NASM-CPT and a B.S. in Applied Kinesiology. 
                        She doubles as a backpacking guide, PSIA Alpine ski instructor, and youth and group fitness specialist. 
                        Her training philosophy? Don't let your fitness level limit your life experiences - whether its big adventures or 
                        just being able to show up for yourself. She has specialized in strength training outdoor and trail-focused athletes since 2019. 
                        She likes to bring awareness to an athlete's joint stability and tendon health, paired with intentional power and 
                        strength development to prevent injury and increase performance in unknown environments and advanced terrain.
                    </p>
                    <p class="coach_certs" ><b>Certifications</b></p>
                    <ul style="display: inline-block; 	text-align: left; ">
                        <li>NASM - CPT </li>
                        <li>B.S. Applied Kinesiology </li>
                    </ul>
                </b-col>

            </b-row>
            <b-row>
                <p> Photos courtesy of Alex.s.Diaz</p>
                <div class="instaHandle">
                    <a href="https://www.instagram.com/alex.s.diaz" target="_blank">
                        <img  src="../assets/instagramGlyph.png"  style="width: 40px; height: auto;">
                    </a>
                    <p class="instaHandleText"> @alex.s.diaz  </p>
                </div>
            </b-row>


        </b-jumbotron>
    </div>
</template>

<script>

export default {
  name: 'CoachesBanner',
  components: {

  },
  data() {
    return {

    
    }
  
  },
  methods: {

    
  }
  

}
</script>
<style>
.instaHandle {
    width:100%; 
    display: flex; 
    flex-direction: column; 
    justify-content: center;
    margin-top: 10px;
}
.instaHandleText {
    font-family: 'Raleway'; 
    font-size:medium; 
    font-weight: bold;
    color: rgb(124, 124, 124); 
    text-decoration: none;
}
.coach_img {
  width: 200px; 
  height: auto; 
  border-radius: 3%;
}
.coach_name {
    font-family: 'Anton';
    /* font-style: italic; */
    /* font-size: 28px; */
    color: #0c5854;
}
.coach_desc {
    font-family: 'Montserrat';

}
.coach_certs {
    text-align: left; 
    margin-left: 5%; 
    margin-bottom: 5px; 
    font-weight: bold;
    font-size: larger;
}
.btn_coach {
    /* align-content: center !important; */
    /* align-self: center !important; */
    align-items: center !important;
    margin: auto !important;
    display: flex !important;
    font-family: 'Montserrat' !important;
    font-style: normal !important;
    font-size: 1rem !important;
    color: white !important;
    min-height: 48px !important;
    max-width: 200px !important;
    border-radius: 15px !important;
    background-color: #8eb6c7 !important;
    border: none !important;
}
@media (max-width: 610px) {
.coach_name {
    font-size: 42px;
}
}
</style>

  