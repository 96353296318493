<template>
    <div >
      <b-jumbotron class="jumboDark" style="padding-top:60px; padding-bottom:60px;">

        
        <b-container >
          <b-row class="justify-content-center" >
            <b-col cols="10" md="8"> 
              <h1 class="ttl"> MEMBERSHIP OPTIONS </h1>
              <div class="lineBreak" style="margin-top: -10px;"></div>
            </b-col>
          </b-row>
          
        </b-container>
        <b-container>
          <b-row class="justify-content-center" style="margin-bottom: 20px;">
            <b-col cols="10" md="5">
              <img  src="../assets/sqr_group_class.png"  style="width: 200px; height: auto; border-radius: 3%;">
              <h1 class="memTtl"> Group Class </h1>
              <ul style="text-align: left;">
                <li> Unlimited group classes (max. 12 people)</li>
                <li> Discounted personal training session ($49) </li>
                <li> Track your progress with TrainHeroic </li>
                <li> Bring a friend to weekend or holiday classes </li>
                <li> Discounted (or free) attendance to in-house workshops </li>
              </ul>
              <h3>$189 - $259/mo </h3>
            </b-col>        
            <b-col cols="10" md="5">
              <img  src="../assets/sqr_pt.png"  style="width: 200px; height: auto; border-radius: 3%;">
              <h1 class="memTtl"> Personal Training </h1>
                <ul style="text-align: left;">
                  <li> 1:1 attention with your coach </li>
                  <li> Custom programming catered to your goals </li>
                  <li> Create a schedule that is convenient for you </li>
                  <li> Discounted (or free) attendance to in-house workshops </li>
                </ul>
                <h3>$90 - $150/session</h3>
            </b-col>
          </b-row>
          <b-row class="justify-content-center" style="margin-bottom: 20px;">
            <b-col cols="10" md="4">
              <img  src="../assets/sqr_open_gym.png"  style="width: 200px; height: auto; border-radius: 3%;">
              <h1 class="memTtl"> Partner Training </h1>
              <ul style="text-align: left;">
                <li> 2:1 attention with your coach </li> 
                <li> Custom programming catered to your goals </li>
                <li> Create a schedule that is convenient for you </li>
                <li> Discounted (or free) attendance to in-house workshops </li>
              </ul>
              <h3>$150/session </h3>
            </b-col>
          </b-row>
        </b-container>
      </b-jumbotron>
    </div>
</template> 

<script>

export default {
  name: 'MembershipTypes',
  components: {

  },
  data() {
    return {

    
    }
  
  },
  methods: {

    
  }
  

}
</script>
<style scoped lang="scss">
.memTtl {
  text-align: center;
  font-family: 'Anton';
}

</style>

  