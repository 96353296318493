<template>
  <div >
    <b-jumbotron class="jumboDark" style="padding-bottom: 40px; padding-top: 20px;">
      
      <b-container>
        <b-row class="justify-content-center align-items-center" style="color:white; padding-top:30px;">
          <b-col cols="10" md="6">
            
            <div>
              <a href="https://www.instagram.com/upliftboulder/" target="_blank">
                  <img  src="../assets/instagramGlyph.png"  style="width: 40px; height: auto;">
              </a>
              <a href="https://www.youtube.com/@upliftboulder/" target="_blank">
                <img  src="../assets/youtubeGlyph.png"  style="width: auto; height: 60px;">
              </a>
              <a href="https://www.facebook.com/upliftboulder/" target="_blank">
                <img  src="../assets/facebookGlyph.png"  style="width: 40px; height: auto;">
              </a>
            </div>
          </b-col>
        </b-row>
        <b-row class="justify-content-center align-items-center">
          <b-col cols="10" md="6">
            <img src="../assets/logo_edit.png" class="logo" >
          </b-col>

        </b-row>
        
        <b-row class="justify-content-center align-items-center" style="color:white; padding-top:30px;">
          <b-col cols="10" md="4">
            <p style="margin-bottom: 0;"> <b>Phone: </b> <br> 303-395-1600</p> 
            <p> <b>Email: </b> <br> inquiry@upliftboulder.com </p>
          </b-col>
          
          <b-col cols="10" md="4">
            <p> <b>Address: </b> <br>
              3280 28th St. <br>
              Suite 4.<br>
              Boulder, CO. 80301<br>
            </p>  
          </b-col>
        </b-row>
      </b-container>

    </b-jumbotron>
    
  </div>
  
</template>

<script>

export default {
  name: 'FooterComp',
  props: {
    // msg: String
    
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
// Theme color default definitions

.logo{
  //display: inline-block;
  max-width:200px;
  margin-left: 5%;
  margin-right: auto;
}
.btnSubmit {
  margin-top: 5px;
  margin-bottom: 15px;
  background-color: black;
  color: white;
  
  border-radius: 5px;
}


</style>

