<template>
    <div>
      <b-jumbotron class="jumboLight" >
        
          <b-container >
          
          <b-row style="text-align: left; padding-left:10%; padding-right:10%; margin-top:30px;" >
            <h3> Sitting is the New Smoking </h3>
            <h3 style="font-variant-caps: all-small-caps; margin-bottom:0;"> APRIL 2023  </h3>
            <p style="color:darkcyan;"> by Devin Perreault  </p>
          </b-row>
          <b-row  >
            <b-col cols="10" lg="10" style="text-align: left; padding-left:10%; margin-top:30px;">
              <p>
                “Sitting is the new smoking”.   At least, that's something that has become a popular conjecture.   Seems a bit extreme, doesn't it?   If we analyze that statement, we can infer that sitting is detrimental to our health and can even shorten our life span.   But, is this true?   Does this statement apply equally to every single person?  As always, the answer is some form of “it depends”.    Let’s discuss what variables come into play when we think about if “sitting is the new smoking”.
                <br><br>
                I’ll admit up front, I do not believe sitting to be the new smoking.  I think a rephrasing of the statement would be better suited to educate people on how to move their bodies:
                <br><br>
                <em>“The worst posture is the one you spend too much time in”.</em>
                <br><br>
                Our bodies adapt to the stresses they are regularly put under.   You see, the body likes to follow the path of least resistance - it likes to be efficient.  If the body is introduced to something regularly enough, it will find a way to do that thing more easily.   Inversely, if something is not practiced often enough, our ability to do that thing may suffer.  Everything we do places a certain amount of stress on the body.   Whether or not that stress creates a positive or negative response is unique to the individual.    
                <br><br>
                Ultimately, it is not sitting that is the issue.  It is the lack in variety of movement that is the biggest concern.   We’ve established that sitting creates an adaptation - when you are sedentary in a chair for a long period of time you are going to get really efficient at stabilizing in that posture.   However, when you get up to move, you may find that you have become less efficient at moving as a result of being still for such a long period of time.
                <br><br>
                Anyone that has ever sat on a non-stop 8 hour flight or road trip knows exactly what I’m talking about.   Standing up after being seated for that long is usually accompanied with an audible groan and a few bodily movements that telegraph to anyone looking “oh boy, do I feel stiff”.
                <br><br>
                Again, <em> “The worst posture is the one you spend too much time in”. </em>
                <br><br>
                Variety in movement is the most important thing.   If you have to sit for long periods of time, make sure you can find ways to get up and move in postures different from that of being in a chair.   
                </p>


            </b-col >
          </b-row>
          </b-container>
        </b-jumbotron>
    </div>
</template>
<script>
export default {
  name: 'BlogApril',
  components: {

  },
  data() {
    return {
       
    
    }
  
  },
  methods: {
    
  }
  
}
</script>