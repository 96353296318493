<template>
  <div>
    <TopNavigation />
    <PageHeader 
      imageUrl='hdr_gettingstarted'
      titleWord1="GETTING" 
      titleWord2="STARTED" 
      description="Whether you've never stepped foot in a gym or you are a seasoned athlete, we start everyone the same." 
    />


    <b-row class="justify-content-center" style="padding-top: 60px; padding-bottom:60px">
      <p style="max-width: 90%">
        At Uplift Boulder, <b>safety is our number one priority</b>. Our only
        pieces of "stationary" equipment are the Assault Bikes and Rowers. This
        means we want to ensure you have the skills and confidence to move
        around our space. <br />
        Our number two priority is that no face goes unseen. Our coaches and
        members welcome everyone by name. Our onboarding process (LAUNCH) is
        proven to cultivate a <b>safe</b> and <b>UPLIFTing</b> environment for all.
      </p>
    </b-row>
    <b-row class="justify-content-center">
      <img src="" class="resImg" style="max-width: 500px" />
    </b-row>
    <LaunchBanner />
    <FAQs />
    <button @click="scrollToTop" class="scroll-to-top"><b-icon-arrow-up/></button>
    <FooterComp />
  </div>
</template>

<script>
import TopNavigation from "@/components/TopNavigation.vue";
import PageHeader from "@/components/PageHeader.vue";
import FooterComp from "@/components/FooterComp.vue";
import FAQs from "@/components/FAQsVue.vue";
import LaunchBanner from "@/components/LaunchBanner.vue";


export default {
  name: "GettingStarted",
  components: {
    TopNavigation,
    PageHeader,
    FooterComp,
    LaunchBanner,
    FAQs,
  },
  data() {
    return {};
  },
  methods: {
  },
};
</script>

<style scoped>
</style>