<template>
    <div>
      <b-jumbotron class="jumboLight" >
        
          <b-container >
          
          <b-row style="text-align: left; padding-left:10%; padding-right:10%; margin-top:30px;" >
            <h3> Should I Lift Heavy? </h3>
            <h3 style="font-variant-caps: all-small-caps; margin-bottom:0;"> JANURARY 2023  </h3>
            <p style="color:darkcyan;"> by Devin Perreault  </p>
          </b-row>
          <b-row  >
            <b-col cols="10" lg="10" style="text-align: left; padding-left:10%; margin-top:30px;">
              <p>
                What do you picture happening when you attempt to lift a weight that is too heavy? 
                Does the weight not budge or does the attempted action result in injury?   
                <br>
                <br>
                I often find that how someone frames their answer describes their relationship with lifting 
                challenging weights.
                <br>

                Training should absolutely involve heavy weights, but heavy means different things for different people.
                One person’s “heavy” might be another’s warm up.    
                "Heavy” can also change on a daily basis, depending on your physical state.    
                Get little to no sleep the night before and what was previously easy can now feel “heavy”.   
                <br>
                <br>
                In the grand scheme of things, what you do in one workout doesn’t matter too much.   
                What does matter, is that you are seeing improvements.   
                Improvements in how your body looks and how your body performs.  
                If you are looking to create positive adaptations, 
                then ideally you have progressed beyond where you were in the past.    
                We refer to this idea as “progressive overload”.    
                In order for progressive overload to occur, the body has to be adequately stressed.

                Too little stress, no change.   This means either too little volume or too little intensity.  
                Too much stress, you’re sore for a week or injured.    
                This means you’ve overdone it with either volume or intensity. 
                <br>
                <br>
                The sweet spot is in between too little and too much.
                The next time you consider going heavier and it makes you anxious, 
                consider these other markers of improvement:
                <br>
                <br>
                -More volume (you did more repetitions than you did previously)
                <br>

                -More efficiency (you performed something more easily or in a shorter time period than before)
                <br>

                -More understanding (you’ve learned something new about yourself or the task at hand)
                <br>
                <br>
                You don’t have to lift heavy, but you need to lift heavier than you did before.   Something has to improve, but it doesn’t always have to be weight.  

              </p>
            </b-col >
          </b-row>
          </b-container>
        </b-jumbotron>
    </div>
</template>
<script>
export default {
  name: 'BlogJan23',
  components: {

  },
  data() {
    return {
       
    
    }
  
  },
  methods: {
    
  }
  
}
</script>