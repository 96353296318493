<template>
  <div>
    <b-jumbotron class="jumboLight">
      <b-container>
        <b-row
          style="
            text-align: left;
            padding-left: 10%;
            padding-right: 10%;
            margin-top: 30px;
          "
        >
          <h3>Why You Should Get an InBody 380 Scan at Uplift Boulder</h3>
          <h3 style="font-variant-caps: all-small-caps; margin-bottom: 0">
            MARCH 2025
          </h3>
          <p style="color: darkcyan">by Jena Franz</p>
        </b-row>
        <b-row>
          <b-col
            cols="10"
            lg="10"
            style="text-align: left; padding-left: 10%; margin-top: 30px"
          >
            <p>
              At Uplift Boulder, we believe that everyone is strong & capable
              and health is so much more than what the scale may see. To dive
              deeper into the metrics that the conventional scale alone can’t
              measure, we have invested in a top of the line body composition
              analyzer - the <b> InBody 380 </b>. <br /><br />
              <b>What Is the InBody 380 Scan?</b>
              <br /><br />
              The InBody 380 is a<b> non-invasive body composition analysis</b>
              that breaks down body composition measurements into key metrics
              like
              <b
                >muscle mass, body fat percentage, and bone mineral
                composition</b
              >. Unlike traditional scales, which only give you a single number,
              this scan provides a
              <b>detailed look at your body’s makeup in less than 60 seconds</b
              >. <br /><br />
              <b>Why Body Composition Matters</b>
              <br /><br />
              Tracking body composition—not just weight—provides you with
              valuable insights into your fitness journey and overall health. In
              comparison to using a traditional scale- increasing muscle mass
              but losing fat mass may not be reflected appropriately on a scale.
              For example, if someone puts on 2lb of muscle and loses 2lb of
              fat, the traditional scale would provide the same bodyweight
              metric. An InBody scan can provide so much more clarity for your
              body composition. This more detailed view can help keep you on
              track towards your fitness goals and aid in better optimizing your
              training program.
              <br /><br />
              Here’s what your InBody 380 Scan provides:<br />
              - <b>Skeletal Muscle Mass (SMM)</b>: Shows how much muscle you have
              and where it’s distributed. <br />
              - <b>Body Fat Percentage (BFP)</b>: A
              more accurate indicator of overall health than BMI.<br />
              - <b>Bone Mineral Content (BMC)</b>: Helps you understand your bone
              density, which is crucial for monitoring and maintaining bone
              health, especially for those at risk for 
              <b>osteopenia or osteoporosis</b>. <br />
              - <b>Segmental Analysis</b>:
              Breaks down fat and muscle distribution across your body for a
              clear picture of imbalances or asymmetries.<br />
              - <b>Total Body Water (TBW)</b>: Tracks your hydration status and
              water balance—important for recovery and performance. 
              <br /><br />
              <b>Special Considerations for Menopausal Women</b>
              <br /><br />
              For women in the <b>perimenopause or menopause</b> phase of life,
              monitoring body composition can be particularly beneficial. It's
              common for women in this stage of life to experience
              <b
                >decreased muscle mass, decreased bone density, and changes in
                fat distribution</b
              >. An InBody scan can allow us to track and assess these changes
              to get the most out of your training program and how to maintain
              and/or rebuild <b>bone health and muscle mass</b>- key factors in
              longevity! <br /><br />
              <b>Who Is This NOT For?</b>
              <br /><br />
              While the InBody scan is a great tool for many, it’s not for
              everyone. You may want to skip the scan if:<br />
              - <b>You have a pacemaker or other implanted electronic medical
                device</b>. The scan uses bioelectrical impedance, which may interfere with
              these devices. We do not allow those with such devices to use the
              machine. <br />
              - <b>You’re pregnant</b>. While it’s safe for pregnancy,
              the scan may not provide useful or accurate information due to
              rapid changes in body composition.
              <b
                >You struggle with body image issues and feel triggered by
                numerical data</b
              >. If seeing precise numbers might negatively affect your
              relationship with your body, we can discuss alternative ways to
              track your progress that focus more on how you feel and what you
              can do, rather than on metrics. <b>You are menstruating</b>. The
              increased fluid in the body during this time may result in
              inaccurate metrics. <br /><br />
              <b>How Often Should You Get an InBody Scan?</b>
              <br /><br />
              We recommend getting a scan every<b> 8–12 weeks </b>to track
              meaningful progress. This interval allows you to monitor long-term
              trends without getting caught up in daily fluctuations. For those
              in targeted programs—like
              <b
                >those competing in strength sports or other sports or managing
                menopausal changes</b
              >—more frequent scans can help fine-tune your approach.
              <br /><br />
              <b>Empower Yourself with Knowledge</b>
              <br /><br />
              An InBody 380 scan is a helpful tool to help gain a deeper insight
              into your body and track your progress in a
              <b>healthy and more informed way</b>. This isn’t about perfection
              or chasing a certain body type—it’s about
              <b>empowering yourself with information</b> to help you make
              choices that support your health and well-being. <br /><br />
              <b
                >Interested in scheduling your InBody scan at Uplift Boulder?</b
              >
              Reach out to us today at inquiry@upliftboulder.com to schedule
              your InBody scan at Uplift Boulder. Let’s continue building
              strength and celebrating every step of your journey together!
              <br /><br />
              Member rate: $35
              <br />
              Nonmember rate: $45
            </p>
          </b-col>
        </b-row>
      </b-container>
    </b-jumbotron>
  </div>
</template>
<script>
export default {
  name: "BlogMarch25",
  components: {},
  data() {
    return {};
  },
  methods: {},
};
</script>