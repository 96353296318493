import { render, staticRenderFns } from "./BlogView.vue?vue&type=template&id=7b970401"
import script from "./BlogView.vue?vue&type=script&lang=js"
export * from "./BlogView.vue?vue&type=script&lang=js"
import style0 from "./BlogView.vue?vue&type=style&index=0&id=7b970401&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports