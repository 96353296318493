<template>
    <div>
      <b-jumbotron class="jumboLight" >
        
          <b-container >
          
          <b-row style="text-align: left; padding-left:10%; padding-right:10%; margin-top:30px;" >
            <h3> Ten Things I Learned From Working Out with My Nonagenarian Grandfather </h3>
            <h3 style="font-variant-caps: all-small-caps; margin-bottom:0;"> DECEMBER 2022  </h3>
            <p style="color:darkcyan;"> by Devin Perreault  </p>
          </b-row>
          <b-row  class="justify-content-center align-items-center">
            <b-col cols="10" lg="8">
              <ol style="text-align: left; padding-left:20%; ">
                <li>You have to keep moving.   No Matter What.</li>
                <li>Don’t let people do things for you if you don’t need them to</li>
                <li>Never be too proud to modify.</li>
                <li>Progress is incremental, especially as we get older.</li>
                <li>Your outlook is just as important as your output.</li>
                <li>Extra reps are compound interest.</li>
                <li>Training should be more rewarding than punishing.</li>
                <li>There is an inverse relationship between intensity and rest.   Moreso with each decade.</li>
                <li>As we age, choosing the right exercises doesn’t change as much as you’d think</li>
                <li>“Let’s see what happens” is the best outlook</li>
              </ol>
            </b-col>
            <b-col cols="10" lg="4">
              <img src = "@/assets/ernie.png" style="max-width: 250px; height: auto;">
            </b-col >
          </b-row>
          <b-row style="min-height: 100px; margin-top:50px; margin-bottom: 20px;;">
            
            <h3 style=""> Watch the Interview with Ernie and Devin on YouTube </h3>
            
            <a href="https://www.youtube.com/watch?v=4FVKijprmn0" target="_blank">
              <b-icon-play-btn-fill scale="5" style="color: darkcyan;">
              </b-icon-play-btn-fill>
            </a>

          </b-row  >
            <b-row class="justify-content-center" style="text-align:left; padding-left:10%; padding-right:10%;">
            <p> 
              When my grandfather Ernie was 85 years old, he started lifting weights again.  
              To my knowledge, this was something he hadn’t done since his years of service in World War 2.   
              <br>
              His weight lifting routine from 85 to 95 was simple.   
              Every day for a period of thirty minutes he repeated the same lifting circuit he learned from a personal trainer at his YMCA.   
              An efficient circuit on machines, he did one set of everything with the machines set to their lowest weight.    
              In addition to resistance training, Ernie would go on walks several times a day.
              <br>
              That’s it.

              <br>
              At no point has Ernie decided that he was too old for anything, he decided to start because he knew he needed to stay active.   
              This all changed when Ernie was 95.  At that time, he underwent a fairly extensive medical procedure that put him in the ICU for 
              2 months then a rehab facility for almost 3 months before he could return home.  When he returned home and was cleared by his 
              physical therapist, I started to take Ernie to the gym twice a week.  Two other times a week, a neighborhood friend would take him.
              <br>
              From age 95 to 99, Ernie and I went to the gym just about every week.  
              In that time, I saw my grandfather go from using a cane to jogging across a gym floor.  
              From lifting weights regularly, Ernie became strong enough to carry home 20lb of bird seed from a hardware store a quarter mile away.  
              His transformation was gradual but profound.  I have no doubt in my mind that I gained additional time with him because of his time 
              in the gym.  We may have been able to train for longer, but when the world shut down in March of 2020 due to a pandemic, our time 
              in the gym was put on pause.  Ernie’s health declined due to his inactivity and he passed away peacefully and comfortably in 
              January of 2021 at the age of 99 and a half.  Today I wanted to share with you ten things I learned from our time training together, 
              time that changed me forever.
            </p>
            <h3> 1. You have to keep moving.   No Matter What.</h3>
            <p> Our bodies adapt to what they are put through regularly.   
              If we introduce the right kind of stress through training, we create positive adaptations that make our favorite activities easier.   
              If we do too little, our bodies adapt to doing less.   
              If we’ve adapted to doing less and ask our bodies to do more, we may have a problem.   
              <br>
              Picture a glass of water.   The size of the glass determines how much liquid it can handle.   
              What we can do, our capacity, is represented by the glass.   Stress, in all its forms, is represented by the liquid.   
              If we have a small glass and need to keep it from overflowing, we need to minimize the amount of liquid we pour in it.  
              If we cannot minimize the amount of liquid, perhaps we need a bigger glass.
              <br>
              Getting older isn’t a reason to do less.  It’s a reason to find ways to do more.  
              <br>
              When I trained with my grandfather, if I took too long to gather a piece of equipment for him to use, 
              he would start doing other exercises without me.   On days that he was tired, we still trained but lowered the intensity.  
              There were very few circumstances where Ernie was not taking full advantage of his body’s ability to move.   
              At almost a century old, Ernie took multiple walks a day and lifted heavy weights three to four times a week.   
              He often told me that all of these activities allowed him to enjoy his life a bit more.
            </p>
            <h3> 2. Don’t let people do things for you if you don’t need them to</h3>
            <p> In the span of one hour I watched my 98 year old grandfather sprint across a room, carry a 20lb medicine ball one 
              hundred feet (for a few sets), perform a collective 50 push ups at a forty five degree angle, and push a 60lb 
              sled two hundred feet with only one break.
              <br>
              Not ten minutes after he was done with his workout, I then witnessed someone open the car door for him 
              and escort him out of the car as if he was made entirely of porcelain and ready to drop at a moment's notice. 
              <br>
              From both a physical and mental standpoint, we need to remain capable.  Our capabilities may change as we get older, 
              but that’s all the more reason to find out what they are.    
              If people are doing everything for us, it may be more efficient in the moment but it may be limiting what we can do in the long term.
              <br>
              This isn’t advice on not asking for help but rather guidance on always seeing what you can do.  Sometimes, you may surprise yourself.  
            </p>
            <h3> 3. Never be too proud to modify.</h3>
            <p> We all have similar muscles and bones, but different training histories and movement considerations.  
              As we get older, there may be greater limits on what we are able to do but that doesn’t mean we shouldn’t move.  
              If barbell bench press aggravates your shoulders as you get older, perhaps it can be helpful to try a dumbbell bench press instead.  
              Focusing on movement patterns rather than specific exercises can reframe what we do in a workout.  
              Instead of not doing something, find a way to scale what you had done in the past.  
            </p>
            <h3> 4. Progress is incremental, especially as we get older.</h3>
            <p> Minutes before getting into the car to take Ernie to the gym I asked him “how many records are we going to set today at the gym?”      
              Ernie gave me a very thoughtful answer that shifted my perspective on what should be achieved each workout. 
              <br>
              “If I perform today like I have in other days and visits, I don’t worry about records.   
              I just do the work, train, and derive benefit from it”
              <br>
              To Ernie, exercise is not about pushing limits but about discovering capabilities.   
              If he gets better, he’s thrilled.   If he’s able to maintain or even find something more challenging one day than it was in the past, 
              he’s still happy to be able to do that thing.   Progress could be lifting more weight or going further on his sled pushes.  
               Progress could also be showing up on a day where he’s a little more tired or less motivated to be the
            </p>
            <h3> 5. Your outlook is just as important as your output.</h3>
            <p> No matter the day, Ernie is full of gratitude to just be alive.   He was a World War 2 veteran who had a few close calls.   
              On a daily basis, he reminded me that there were many moments where he didn’t think he’d make it to age 21.   
              I’m glad he did because I otherwise wouldn’t be here.     This idea of borrowed time has created a lens with which Ernie viewed life.   
              He was full of wonder and happy to be where he was, wherever he was.  
              <br>
              Exercising can be seen as a chore. Something that you have to do.  Akin to plugging your nose and eating whatever undesirable vegetable is on your plate.

              <br>
              Or
              <br>
              It can be seen as a gift.  The ability to move, however you can move, as something that may not always be promised to you.  
              The ability to make physical improvements is the ultimate freedom.    
              While not everything may be under our control, we can still delight in improving what we can.

            </p>
            <h3> 6. Extra reps are compound interest.</h3>
            <p> The more you do, the more you can do.    The idea of sets and reps is a bit arbitrary.   
              Whether you do eight sets of 3 reps or three sets of 8 reps, you’re still doing 24 overall repetitions.   
               What matters is that you make continuous progress.   If you can do more, the odds of an increased benefit are in your favor. 
            </p>   
            <h3> 7. Training should be more rewarding than punishing.</h3>
            <p> During every single workout I expected my grandfather to shout out “oh that's nice!” when handed a heavy weight.  
              When he ran faster, he smiled wider.   The harder he seemed to work, the more joy he experienced.   
              <br>
              We’d been at this for four years and his reactions to training hard seemed to stem from the reminder that his being able 
              to move meant that he was still here.  Ernie loved to train because Ernie loved to be alive.   
              He knew that a stronger body is a more capable one and each workout was a reward to himself with the gift of movement.
            </p>
            <h3> 8. There is an inverse relationship between intensity and rest.   More so with each decade.</h3>
            <p> If you do enough repetitions of something, you’re going to get tired.    
              If you do enough sets of something, you’ll find that lifting that same weight might become unrealistic for another set.   
              We call this fatigue.   If the workout is intense enough, we will need a day or two to recover from the fatigue created.   
              Unless you are learning a new skill, the benefits from a workout are not in the moment but during the recovery time.   
              <br>
              As the body ages, recovery takes a bit more time.
            </p>
            <h3> 9. As we age, choosing the right exercises doesn’t change as much as you’d think.</h3>
            <p> I tell people all the time, Ernie and I would do the same exercises.   
              I compete in many strength sports, my grandfather had no interest in testing his absolute limits like I do.    
              Still, we both have the same muscles and utilize the same movement patterns.   
              Ernie will do modified push-ups, I’ll train to see how much I can bench press.   
              Ernie carries a 20lb medicine ball one hundred feet, I’ve done competitions where I have to carry 220lb in each hand.   
              <br>
              The main differences between what Ernie and I do (besides the obvious difference in weight lifted) 
              are ranges of motion and the tools we use to accomplish a task.

              <br>
              Take lunges for example.   It's probably been a while since my grandfather could do a set of walking lunges.   
              Instead, he might step up onto a box or push a sled to move his legs in a very similar manner.   
              Getting Ernie to do a pull up might be not be feasible, but he can certainly do some lat pull downs.  
              <br>
              The older we are, the more wear and tear we have to consider.
              <br>
              Modify range of motion, modify where the load is distributed, modify the complexity. 

            </p>
            <h3> 10. “Let’s see what happens” is the best outlook.</h3>
            <p>  Ernie would rarely go into a workout with a specific conceived notion of what he was able to do.  
              He was curious with what his body could do on any particular day and he let his experience be the guide of what level 
              of intensity he would pursue in his workout.  On days where he was more tired, he scaled back.  
              On days where he felt especially energized, he’d try something new or add a little more weight.  
              It can be easy to define ourselves solely by what has happened in the past or what we feel we should be doing at a certain age.  
              I rarely saw my grandfather use these preconceived notions to define his capabilities, instead he 
              relied on how he felt and showed up that day.  I’m convinced that if he decided he was ever too old, 
              he wouldn’t have been able to create the strong and resilient body he had later in life.  
            </p>  
          </b-row>
        </b-container>
        </b-jumbotron>
    </div>
</template>

<script>


export default {
  name: 'BlogDec22',
  components: {

  },
  data() {
    return {
       
    
    }
  
  },
  methods: {
    
  }
  
}
</script>