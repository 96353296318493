<template>
  <div>
    <TopNavigation />
    <PageHeader
      imageUrl="img_group1"
      titleWord1="START"
      titleWord2="TODAY"
      description="Drop us a quick note, and we'll get you scheduled for a time that's convenient for you."
    />
    <b-row
      class="justify-content-center align-items-center"
      style="margin-top: 5%; margin-bottom: 5%"
    >
      <b-col cols="10" md="5">
        <img
          src="../assets/wallMural.png"
          style="width: 100%; max-width: 500px"
        />
      </b-col>
      <b-col cols="10" md="5">
        <div>
          <b-row class="justify-content-center">
            <h3 style="font-variant-caps: all-small-caps">
              Please fill out the form below and we'll get back to you ASAP
            </h3>
          </b-row>
          <b-form inline @submit="onSubmit" class="form1">
            <b-input
              class="input1"
              v-model="form.firstName"
              placeholder="First Name"
              id="firstName"
              required
            />
            <b-input
              class="input1"
              v-model="form.lastName"
              placeholder="Last Name"
              id="lastName"
              required
            />
            <b-input
              class="input1"
              v-model="form.email"
              placeholder="Email"
              id="email"
              :state="stateEmail"
              required
            />
            <b-input
              class="input1"
              v-model="form.phone"
              placeholder="Phone Number"
              @input="validatePhone"
              :state="statePhoneLength"
            />
            <b-form-textarea
              class="input1"
              style="height: 120px"
              v-model="form.notes"
              placeholder="Anything you want us to know before we call? Limited to 200 characters."
              id="notes"
              :state="form.notes.length <= 400"
            />
            <b-form-select
              class="select1"
              v-model="form.interest"
              :options="options"
              id="membership"
            />
            <b-btn type="submit" class="btnSubmit" style="margin-top: 10px"
              >Submit</b-btn
            >
          </b-form>
        </div>
        <p v-if="showResponse" style="margin-top: 5px; color: darkcyan">
          Thank you for reaching out. We will be in touch shortly!
        </p>
      </b-col>
    </b-row>
    <b-modal
      v-model="formSubmit"
      hide-backdrop
      hide-footer
      title="Form Submitted"
    >
      <p>Thank you for reaching out! We'll be in touch shortly.</p>
    </b-modal>
    <GoogleReviewBanner />
    <FooterComp />
  </div>
</template>

<script>
// @ is an alias to /src
import TopNavigation from "@/components/TopNavigation.vue";
import PageHeader from "@/components/PageHeader.vue";
import FooterComp from "@/components/FooterComp.vue";
import emailjs from "emailjs-com";
import GoogleReviewBanner from "@/components/GoogleReviewBanner.vue";

export default {
  name: "ContactView",
  components: {
    GoogleReviewBanner,
    TopNavigation,
    PageHeader,
    FooterComp,
  },
  data() {
    return {
      enableEmails: true,
      formSubmit: false,
      options: [
        { value: null, text: "What are you most interested in? " },
        { value: "group class", text: "Group Class" },
        { value: "open gym", text: "Open Gym" },
        { value: "personal training", text: "Personal Training" },
        { value: "general", text: "I just have some questions" },
      ],
      form: {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        notes: "",
        interest: null,
      },
      showResponse: false,
    };
  },
  computed: {
    statePhoneLength() {
      return (this.form.phone.length == 0) | (this.form.phone.length == 14)
        ? true
        : false;
    },
    stateEmail() {
      return this.form.email.includes("@") && this.form.email.includes(".")
        ? true
        : false;
    },
  },
  methods: {
    validatePhone() {
      var x = this.form.phone
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.form.phone = !x[2]
        ? x[1]
        : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
    },

    async onSubmit(event) {
      if (this.form.interest == null) {
        this.form.interest = "general";
      }
      if (this.enableEmails) {
        event.preventDefault();
        emailjs.init(process.env.VUE_APP_EMAILJS_UID);
        // alert(JSON.stringify(this.form))
        var templateParams = {
          firstName: this.form.firstName,
          lastName: this.form.lastName,
          email: this.form.email,
          phone: this.form.phone,
          notes: this.form.notes,
          interest: this.form.interest,
        };

        emailjs
          .send(
            process.env.VUE_APP_EMAILJS_SERVICE_ID,
            process.env.VUE_APP_EMAILJS_TEMPLACE_GENERAL_ID,
            templateParams,
            process.env.VUE_APP_EMAILJS_UID
          )
          .then(
            function (response) {
              console.log("SUCCESS!", response.status, response.text);
            },
            function (error) {
              console.log("FAILED...", error);
            }
          );
      }

      // Reset our form values
      this.form.firstName = "";
      this.form.lastName = "";
      this.form.email = "";
      this.form.phone = "";
      this.form.notes = "";
      this.form.interest = "";
      this.showResponse = true;
      this.formSubmit = !this.formSubmit;
    },
  },
};
</script>
<style scoped lang="scss">
.form1 {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin-top: 20px;
}
.input1 {
  width: 80%;
  margin-top: 20px;
  height: 40px;
}
.select1 {
  width: 300px;
  height: 40px;
  margin-top: 20px;
  border: 1px solid #ced4da;
  border-radius: 0.372rem;
  color: #73797e;
}
@media (max-width: 700px) {
  .form1 {
    margin-top: 5px;
  }
  .input1 {
    width: 95%;
  }
}
</style>