<template>
    <div>
        <b-jumbotron class="jumboDark" >
      <b-container style="max-width: 600px; margin-bottom: 30px;">
        <b-row>
          <b-col cols="3" class="galleryBk"><p class="galleryTxt">Scalable</p></b-col>
          <b-col cols="3" ><img src="../assets/galleryGroup3.png"  class="imgGallery" ></b-col>
          <b-col cols="3" ><img src="../assets/galleryEq2.png"  class="imgGallery" ></b-col>
          <b-col cols="3" ><img src="../assets/gallerySolo1.png"  class="imgGallery" ></b-col>
        </b-row>
        <b-row style="margin-top:10px;">
          <b-col cols="3" ><img src="../assets/galleryEq1.png"  class="imgGallery" ></b-col>
          <b-col cols="3" class="galleryBk"><p class="galleryTxt">Empowering</p></b-col>
          <b-col cols="3" ><img src="../assets/galleryGroup4.png"  class="imgGallery" ></b-col>
          <b-col cols="3" ><img src="../assets/galleryEq4.png"  class="imgGallery" ></b-col>
        </b-row>
        <b-row style="margin-top:10px;">
          <b-col cols="3" ><img src="../assets/galleryGroup1.png"  class="imgGallery" ></b-col>
          <b-col cols="3" ><img src="../assets/galleryEq3.png"  class="imgGallery" ></b-col>
          <b-col cols="3" class="galleryBk"><p class="galleryTxt">Challenging</p></b-col>
          <b-col cols="3" ><img src="../assets/galleryGroup2.png"  class="imgGallery" ></b-col>
        </b-row>
        <b-row style="margin-top:10px; margin-bottom:60px;" >
          <b-col cols="3" ><img src="../assets/galleryGroup5.png"  class="imgGallery" ></b-col>
          <b-col cols="3" ><img src="../assets/gallerySolo2.png"  class="imgGallery" ></b-col>
          <b-col cols="3" ><img src="../assets/galleryEq5.png"  class="imgGallery" ></b-col>
          <b-col cols="3" class="galleryBk"><p class="galleryTxt">Supportive</p></b-col>
        </b-row>
      </b-container>
    </b-jumbotron>
    </div>
</template>

<script>


export default {
  name: 'GalleryAttributes',
  components: {

  },
  data() {
    return {
       
    
    }
  
  },
  methods: {
    
  }
  
}
</script>