<template>
  <div>
    <TopNavigation />
    <PageHeader
      imageUrl="img_plank_wide"
      titleWord1="MEMBERSHIP"
      titleWord2="OPTIONS"
      description="Your training may evolve over time. We want to evolve with you."
    />
    <MembershipTypesVertical />
    <MembershipPricing />
    <LaunchBanner />
    <GroupClassTypes />
    <PolicyBanner />
    <button @click="scrollToTop" class="scroll-to-top">
      <b-icon-arrow-up />
    </button>
    <FooterComp />
  </div>
</template>

<script>
// @ is an alias to /src
import TopNavigation from "@/components/TopNavigation.vue";
import PageHeader from "@/components/PageHeader.vue";
import FooterComp from "@/components/FooterComp.vue";
import PolicyBanner from "@/components/PolicyBanner.vue";
import GroupClassTypes from "@/components/GroupClassTypes.vue";
import MembershipTypesVertical from "@/components/MembershipTypesVertical.vue";
import MembershipPricing from "@/components/MembershipPricing.vue";
import LaunchBanner from "@/components/LaunchBanner.vue";

export default {
  name: "MembershipView",
  components: {
    TopNavigation,
    PageHeader,
    FooterComp,
    PolicyBanner,
    GroupClassTypes,
    MembershipTypesVertical,
    MembershipPricing,
    LaunchBanner,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.litems {
  text-align: left;
}
.memContainer {
  padding-top: 30px;
  padding-bottom: 30px;
}
.memOpt {
  max-width: 900px;
}

// for mobile displays
@media (max-width: 700px) {
  #classLabel {
    display: none;
  }
  .memOpt {
    padding: 0;
  }
  .showWidget {
    visibility: hidden;
    height: 10px;
  }
}
</style>
