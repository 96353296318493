<template>
    <div >
      <b-jumbotron class="jumboLight" >
        <b-container >
          <b-row style="text-align: left; padding-left:10%; padding-right:10%; margin-top:30px;" >
            <h3> What the Body Understands About Training </h3>
            <h3 style="font-variant-caps: all-small-caps; margin-bottom:0;"> FEBRUARY 2023  </h3>
            <p style="color:darkcyan;"> by Devin Perreault  </p>
          </b-row>
          <b-row  >
            <b-col cols="10" lg="10" style="text-align: left; padding-left:10%; margin-top:30px;">
              <p>
              I could talk for hours about the benefits of strength training.   
              To some, that may be defined as how much weight someone can bench press or deadlift, 
              but I see it differently.   
              Strength is the ability to move and control your body in space.   
              Strength is the ability to be resilient to whatever stresses life throws your way.   
              Strength is capability.  If someone can bench press 200lb with ease but needs 
              10 seconds to get up off of the floor, how capable are they really?   
              Strength is durability.  If someone can pick 200lb off of the floor 
              but then needs a week to recover their lower back after doing so, 
              how durable are they really?
              <br>
              <br>
              Our goal at Uplift Boulder is to teach you how you can become stronger 
              over the long term so that you can enjoy your favorite activities for 
              as long as possible.  We want to teach you how to move better, 
              recover faster, and inspire others to do the same.  
              We believe education is an important piece of that.   
              <br>
              <br>
              Too often we assign dogma to particular types of exercise.   
              The truth is, every type of exercise can be effective.  
              However, for particular goals, some things are better suited than others.   
              It doesn’t matter how you get strong, it just matters that you are strong.  
              If the goal is to live as capably and independently as possible, then the goal 
              should be to continue to be strong.   
              </p>

              <h2>
              What Your Body (Not Your Brain) Understands About Exercise
              </h2>

              <p>
              There are only three things that matter when it comes to training.  
              For all of the complexities that can be considered when choosing the 
              right exercise program, they can be distilled to only three things.   
              This does not discredit complexity, it just highlights the similarities 
              in every training modality.   
              Before I tell you what these three things are, 
              I want to first mention that everything works.   
              Everything.    No matter how you choose to exercise, it will be effective.   
              However, whether that form of exercise is effective long term or short term, 
              that is to be determined.   Everything we do creates an adaptation.   
              By that definition, every type of exercise will create an adaptation.   
              Now, whether that adaptation is progressive and sustainable long term 
              (you can get stronger lifting with a rounded back, but for how long?) 
              is the most important question.   Whatever you decide to do, ask 
              yourself “will I be able to do this a year from now?  Five years from now?  
              Ten?  Twenty?”
                <br>
                <br>
              Now, for those three things:
              </p>
              <ul>
              <li>Joint angles</li>
              <li>Time under tension</li>
              <li>Energy systems</li>

              </ul>
              <p>
              That’s it.

              Every form of exercise is a variant on those three things.  Let’s elaborate.   
              </p>


              <h2>Joint Angles</h2>
              <p>
              Essentially, this is your body’s way of determining where you are in space.   
              Joint angles describe the positions of two segments on either side of a particular joint.  
              The sense of the body knowing where it is in space is called “proprioception”.   
              Your muscles/tendons/joints/fascia themselves do not have a sense of proprioception, 
              but they do provide information that is sent to the brain for interpretation.   
              As far as your muscles/tendons/joints are concerned, it doesn’t matter if you do 
              a push up or a bench press, both exercises will take you through essentially 
              the same range of motion.    There is absolutely a time where one exercise may 
              be preferable to another, especially when considering specificity 
              (if you are going to enter a bench press competition, you’ll have to train 
              with the bench press) and comfort (if bench press is uncomfortable on your shoulders, 
              perhaps push ups can be a safer alternative), but consider exercises that train 
              through similar ranges of motion is being interchangeable for the purposes of 
              building general strength and capability.  
              </p>
              <h2>Time Under Tension</h2>
              <p>

              Time under tension describes how long the muscles/tendons/bones/fascia are under load.   
              Have you ever wondered how many repetitions of a particular exercise you should 
              perform during a set?   Understanding how much “time under tension” is needed to 
              make the particular change you are looking for will answer your question.  
              Looking to build speed and power?  Time under tension will be minimal as you aim 
              to move quickly without incurring a lot of fatigue, anywhere from .1 seconds to 
              10 seconds (roughly).   Looking to develop strength?   Time under tension will be a 
              little longer and your speed a little more moderate while incurring a moderate amount 
              of fatigue (20-40 seconds, roughly).   If you are looking to develop hypertrophy, 
              time under tension will be much longer and speed a bit slower while incurring a 
              moderate to high level of fatigue (60-90 seconds, roughly).    
              <br>
              Why does this matter?   Well, it shows us that we can use the same exercise for multiple purposes.   Exercises are simply tools to accomplish a task.   Knowing what we want to accomplish determines how we use that tool.   
              </p>


              <h2>Energy Systems</h2>
              <p>
              Movement and body functions are made possible by the food that we eat.   
              That food, made up of fats, proteins, and carbohydrates, is broken down, 
              respectively, into fatty acids, amino acids, and glucose which is then either 
              utilized or stored.   In order for movement and body functions to occur, the body 
              has to create ATP, the currency of energy.   As the body needs a constant supply of 
              energy, ATP (the currency of energy) is always being created and utilized.  
              <br>
              In short, what we eat powers our cells.   What kind of power we need is determined 
              by the demands placed on our bodies.   
              <br>
              There are three energy systems in the body, but for the sake of simplicity we won’t 
              go into much detail about them yet.   Just consider the physical differences between 
              a 100 meter sprinter and a marathon runner.   They participate in the same activity 
              but their bodies have adapted in very different ways.   For now, just consider that 
              the three energy systems in the body are operating at all times.   
              The degree with which each system is utilized at a particular moment is 
              determined by the type of activity and the person’s fitness level.   
              </p>

              <h2>Putting It All Together</h2>
              <p>
              Every day is a chance to get stronger and to move better.  
              No matter what we do, our bodies are creating adaptation to those particular activities.   
              If we sit all day, we will get really good at sitting.   Is that a positive adaptation?    
              Think back to the last time you sat in a car or plane for 6+ hours straight 
              and then got up to move - how did you feel?
              <br>

              What we do doesn’t matter so much as the simple fact that we do it.   
              With whatever you decide to do, just keep in mind the three things your body understands:
              </p>
              <ul>
              <li>Joint angles</li>
              <li>Time under tension</li>
              <li>Energy systems</li>

              </ul>

              <p>
              If we had to sum up our philosophy of training at Uplift Boulder in one sentence, it would be this:
              <br>
              <br>

              <b>Prepare your body for the stresses that come with the activities that you enjoy.</b>
              <br>
              <br>
              It doesn’t matter what you do, it just matters that you do it.   
              There is no best exercise, your body has no idea what exercises you are doing.  
              It doesn’t even know if you’re using a kettlebell, dumbbell, or Shake Weight.  
              It is the brain that interprets all of this.  
               It doesn’t matter how you get strong, it just matters that you get strong.   
               It doesn’t matter how you move your joints through a full range of motion, 
               it just matters that you do it daily.     However you like to move, just move and do it a lot.   
               Just make sure you can continue doing that thing progressively and sustainably.  
              </p>

              
            </b-col >
          </b-row>
        </b-container>
      </b-jumbotron>
    </div>
</template> 

<script>

export default {
  name: 'BlogFeb23',
  components: {

  },
  data() {
    return {

    
    }
  
  },
  methods: {

    
  }
  

}
</script>
<style>

</style>

  