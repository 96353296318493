<template>
    <div >
      <b-jumbotron class="jumboLight" >
        <b-container >
          <b-row style="text-align: left; padding-left:10%; padding-right:10%; margin-top:30px;" >
            <h3> Load, Capacity and Mitigating Injury  </h3>
            <h3 style="font-variant-caps: all-small-caps; margin-bottom:0;"> MARCH 2023  </h3>
            <p style="color:darkcyan;"> by Devin Perreault  </p>
          </b-row>
          <b-row  >
            <b-col cols="10" lg="10" style="text-align: left; padding-left:10%; margin-top:30px;">
              <p style="color:darkmagenta">
                
                <b> Load > Capacity = Injury 
                <br>
                Load ≤ Capacity = Rehab
                <br>
                Capacity >> Load = Mitigation
                <br>
                </b>
                </p>
                <p>
                These three equations sum up the purpose of training as simply as I have ever seen.  
                Taken from 
                <span>
                  <a 
                  style="text-decoration: none; font-style: normal;"
                  target="_blank"
                  href="https://postcompetitiveinsight.com/2016/01/the-interesting-science-behind-functional-range-conditioning.html">
                  Dr. Andreo Spina’s  </a>
                </span>
                teachings, each equation describes a different outcome 
                as the variables of Load and Capacity are adjusted.   
                When it comes to our bodies, Load can be described as any internal or external 
                force produced on any of the body’s connective tissues (muscles, tendons, fascia, etc).   We can measure Load many ways, through volume, intensity, frequency.   Capacity is the body’s ability to handle Load.
                <br>
                <br>
                <b>Think of CAPACITY as an empty glass and LOAD as the liquid placed in the glass.
                  The larger your glass, the more liquid 
                  you can place in it.   </b>
                <br>
                <br>
                When our glass is too small for the liquid it needs to carry, issues arise.  
                In life, this means the daily demands we place on ourselves are more than we can handle.   
                Quite often this issue manifests itself through the presence of pain, a signal that lets 
                you know something needs to change.   
                <br>
                <br>
                <b>There are two ways to adjust with this reality: do less, or become more capable.</b>
                <br>
                <br>
                Uplift Boulder’s main purpose is to teach you how to increase the size of your glass.   
                A stronger body whose joints move well through a larger range of motion is more prepared 
                to handle the stresses and activities of life.
                <br>
                <br>
                Resistance training is by far the most effective tool we can use to better equip our 
                bodies to handle the stresses of life (1).   
                Resistance training has been proven to increase the strength of muscles, 
                tendons, ligaments, cartilage, and fascia.   
                It decreases the likelihood of overuse injuries (golfer’s elbow, tennis elbow, etc).   
                Resistance training is your most effective tool in being able to enjoy your favorite 
                activities for longer.    A stronger body is a more capable one.
                <br>
                <br>
                Uplift Boulder’s primary goal is to give you the best program for your body, 
                custom tailored based on your previous experience and the right assessments.   
                We may all have the same muscles and bones, but our considerations change based on 
                our history and our goals.   Uplift Boulder aims to provide the most effective solutions 
                based entirely on where you are at the moment.    

                </p>
                <p style="margin-bottom: 0;"> <b>References</b>
                  
                </p>
                <p>
                  1. 
                  <span>
                    <a 
                      targer="_blank"
                      href="https://pubmed.ncbi.nlm.nih.gov/3633121/">
                      Fleck SJ, Falkel JE. Value of resistance training for the reduction of sports injuries. Sports Med. 1986 Jan-Feb;3(1):61-8.
                    </a>
                  </span>
                </p>



              
            </b-col >
          </b-row>
        </b-container>
      </b-jumbotron>
    </div>
</template> 

<script>

export default {
  name: 'BlogMarch23',
  components: {

  },
  data() {
    return {

    
    }
  
  },
  methods: {

    
  }
  

}
</script>
<style>

</style>

  