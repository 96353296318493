<template>
  <div >
    <b-jumbotron class="jumboDark justify-content-center" >
      
      <header class="header justify-content-center" >
        <!-- Logo -->
        <img src="../assets/logo_edit.png" class="logo" >
        
        <!-- Mobible Menu Icon -->
        <b-button class="menuBtn" v-b-toggle.sideMenu><b-icon-list class="menuIcn"  ></b-icon-list></b-button>

        <!-- Menu -->
        <nav id="nav" :style="show ? filterStyle : null" class="filter">
          <ul class="menu">
            <router-link to="/" >Home</router-link>
            <router-link to="/membership" >Membership</router-link>
            <router-link to="/about" >About</router-link>
            <router-link to="/gettingstarted">Getting Started</router-link>
            <router-link to="/blog" >Blog</router-link>
            <router-link to="/events" >Events</router-link>
            <router-link to="/contact" >Contact</router-link>
          </ul>
        </nav>
        <b-sidebar id="sideMenu"  shadow right bg-variant="dark" no-header>
          <b-button class="menuBtn" v-b-toggle.sideMenu style="margin-top:5%; "><b-icon-x class="menuIcn"></b-icon-x></b-button>
          <nav id="mobileNav" >
            <ul class="mobileMenu">
              <router-link to="/" >Home</router-link>
              <router-link to="/membership" >Membership</router-link>
              <router-link to="/about" >About</router-link>
              <router-link to="/gettingstarted">Getting Started</router-link>
              <router-link to="/blog" >Blog</router-link>
              <router-link to="/events" >Events</router-link>
              <router-link to="/contact" >Contact</router-link>
            </ul>
          </nav>
          
        </b-sidebar>
        

        
      </header>
    </b-jumbotron>
    
  
  </div>
  
</template>

<script>
export default {
  name: 'TopNavigation',
  props: {
    // msg: String
    
  },
  data() {
    return {
      show: false,
      filterStyle: {
        display: "block",
      },
    }
  },
  methods: {
    showMenu(){
      this.show = !this.show;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
// Theme color default definitions


// large displays
.promoHdr1 {
  //background-color: darkcyan;
  background-image: linear-gradient(90deg, #d8e030, black);
  height: fit-content;
  padding: 10px;
  margin: 10px auto;
  
}
.hdrBtn {
  background-color: black !important;
  font-variant: small-caps; 
  border-radius: 10px !important;
  
}
.header{
    position: sticky;
    padding-top:20px;
    //top: 0;
    width: 100%;
    display: flex;
    align-items: flex-end;
    max-width: 1500px;
    margin: auto;
}

#nav {
  width: 100%;
  margin-right:5%;
  display: flex;
  justify-content: flex-end;
}
#nav a{
  background-color: black;
  border: black;
  border-radius: 5px;
  color: white;
  text-decoration: none;
  font-family: 'Segoe UI', Tahoma, sans-serif;
  font-size: 1.15rem;
  transition-duration: 0.4s; //Use the transition-duration property to determine the speed of the "hover" effect:
  margin: auto 10px auto 10px;
}
#nav a:hover {
  background-image: radial-gradient(rgba(90, 90, 90, 0.5),rgba(75, 75, 75, 0.5),black,  );
}

#nav a.router-link-exact-active {
  
  text-decoration: underline;
  text-decoration-color: #d8e030;
  text-decoration-style:solid;
  text-decoration-thickness: 4px;
}
.logo{
  //display: inline-block;
  max-width:150px;
  margin-left: 5%;
  margin-right: auto;
  
}
.menuBtn {
    display: none;
  }
// for mobile displays
@media (max-width: 800px) {
  #nav {
    display: none;
  }
  #sideMenu {
    background-color: black;
  }
  #mobileNav {
    width: 100%;
    margin-right:5%;
    display: block;
    
  }
  #mobileNav a{
    display: block;
    background-color: black;
    
    color: white;
    text-decoration: none;
    font-family: 'Segoe UI', Tahoma, sans-serif;
    font-size: 1.25rem;
    transition-duration: 0.4s; //Use the transition-duration property to determine the speed of the "hover" effect:
    margin: 10px 10px 10px 10px;
  }
  #mobileNav a:hover {
    background-image: radial-gradient(rgba(90, 90, 90, 0.5),rgba(75, 75, 75, 0.5),black,  );
  }

  #mobileNav a.router-link-exact-active {
    
    text-decoration: underline;
    text-decoration-color: #d8e030;
    text-decoration-style:solid;
    text-decoration-thickness: 4px;
  }
  .menuBtn {
    background: none;
    margin-bottom: 5%;
    margin-right: 5%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .menuIcn{
      color: white;
      width: 30px;
      height: 30px;
  }
  .filterStyle {
    display: flex;
  }
  
}

</style>
