<template>
  <div>
    <TopNavigation />
    <PageHeader
      imageUrl="hdr_blog"
      titleWord1="STRENGTH"
      titleWord2="UNLEASHED"
      description="Welcome to our strength training hub—a blog dedicated to empowering your fitness journey."
    />

    <div ref="blogDisplayed">
      <BlogMar25 v-if="blogNum == 0" />
      <BlogApril v-if="blogNum == 1" />
      <BlogMar23 v-if="blogNum == 2" />
      <BlogFeb23 v-if="blogNum == 3" />
      <BlogJan23 v-if="blogNum == 4" />
      <BlogDec22 v-if="blogNum == 5" />
    </div>

    <b-jumbotron class="jumboLight" style="margin-bottom: 60px">
      <b-container>
        <b-row class="justify-content-center" style="margin-top: 60px">
          <b-col cols="10" md="6">
            <h1 class="ttl">Previous Articles</h1>
            <div class="lineBreak" style="margin-top: -10px"></div>
          </b-col>
        </b-row>
        <b-row class="justify-content-center" style="padding: 10px">
          <b-col cols="8" md="4" style="text-align: left">
            <div @click="changeBlog(0)" style="cursor: pointer">
              <h2 class="blogDate">- MARCH 2025 -</h2>
              <h3>InBody 380 Scan</h3>
            </div>
          </b-col>
          <b-col cols="8" md="4" style="text-align: left">
            <div @click="changeBlog(1)" style="cursor: pointer">
              <h2 class="blogDate">- APRIL 2023 -</h2>
              <h3>Sitting is the New Smoking</h3>
            </div>
          </b-col>
          <b-col cols="8" md="4" style="text-align: left">
            <div @click="changeBlog(2)" style="cursor: pointer">
              <h2 class="blogDate">- MARCH 2023 -</h2>
              <h3>Load, Capacity and Mitigating Injury</h3>
            </div>
          </b-col>
          <b-col cols="8" md="4" style="text-align: left">
            <div @click="changeBlog(3)" style="cursor: pointer">
              <h2 class="blogDate">- FEBRUARY 2023 -</h2>
              <h3>What the Body Understands About Training</h3>
            </div>
          </b-col>
        </b-row>
        <b-row class="justify-content-center" style="padding: 10px">
          <b-col cols="8" md="6" style="text-align: left">
            <div @click="changeBlog(4)" style="cursor: pointer">
              <h2 class="blogDate">- JANUARY 2023 -</h2>
              <h3>Should I Lift Heavy?</h3>
            </div>
          </b-col>
          <b-col cols="8" md="6" style="text-align: left">
            <div @click="changeBlog(5)" style="cursor: pointer">
              <h2 class="blogDate">- DECEMBER 2022 -</h2>
              <h3>Ten Things I Learned From My Grandfather</h3>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </b-jumbotron>
    <FooterComp />
  </div>
</template>

<script>
import TopNavigation from "@/components/TopNavigation.vue";
import PageHeader from "@/components/PageHeader.vue";
import FooterComp from "@/components/FooterComp.vue";
import BlogDec22 from "@/components/blog/BlogDec22.vue";
import BlogJan23 from "@/components/blog/BlogJan23.vue";
import BlogFeb23 from "@/components/blog/BlogFeb23.vue";
import BlogMar23 from "@/components/blog/BlogMar23.vue";
import BlogApril from "@/components/blog/BlogApril.vue";
import BlogMar25 from "@/components/blog/BlogMar25.vue";

export default {
  name: "BlogView",
  components: {
    TopNavigation,
    PageHeader,
    FooterComp,
    BlogDec22,
    BlogJan23,
    BlogFeb23,
    BlogMar23,
    BlogApril,
    BlogMar25,
  },
  data() {
    return {
      blogNum: 0,
    };
  },
  methods: {
    changeBlog(e) {
      this.blogNum = e;
      this.$refs["blogDisplayed"].scrollIntoView({ behavior: "smooth" });
    },
  },
};
</script>
<style  lang="scss">
.blogDate {
  font-variant-caps: all-small-caps;
  margin-bottom: 0;
  font-weight: 500;
  color: #adadad;
}
</style>