<template>
  <div>
    <b-jumbotron class="jumboDark justify-content-left align-items-center">
      <div class="main_header launch_header">
        <div class="justify-content-left align-items-center launch_info">
          <h1 class="ttl" style="text-align: left; margin-left: 5px">
            <font color="white"> UPLIFT </font>
            <font color="#d8e030"> LAUNCH </font>
          </h1>
          <h3
            style="
              margin-bottom: 5px;
              margin-left: 5px;
              text-align: left;
              text-shadow: 3px 0px 0px black;
            "
          >
            Our 21 Day Launch Program allows you to get a taste of what Uplift
            Boulder is all about. To set you up for success this program begins
            with a personal training session and includes 21 days of group
            classes and open gym access. No strings attached!
          </h3>
        </div>
      </div>

      <b-container style="margin-top: 60px; margin-bottom: 100px">
        <!-- launch program -->
        <b-row
          class="justify-content-center align-items-center"
          style="margin-top: 10px"
        >
          <b-container class="launchBox">
            <b-row class="justify-content-center align-items-center">
              <b-col cols="11" md="10">
                <p class="desc">Free hour of personal training</p>
                <p class="subDesc">
                  Kick off your launch program with a personal training session
                  where you’ll review gym safety, proper lifting techniques, and
                  get a brief intro to what life at Uplift is like!
                </p>
                <p class="desc">No Commitment</p>
                <p class="subDesc">
                  Use these 21 days to see if we are a good fit for you!
                </p>
                <p class="desc">Unlimited group class</p>
                <p class="subDesc">
                  Test out our various classes and find your favorite coach.
                </p>
              </b-col>
              <b-col cols="6" md="2">
                <!-- <h2 class="price" style="text-decoration: line-through; font-size: 22px; margin: auto -5px 0 auto;">$169</h2> -->
                <h2 class="price">$179</h2>

                <!-- <p style="font-style: italic; font-size: smaller;"> Limited time offer.  </p> -->
              </b-col>
            </b-row>
            <b-row
              class="justify-content-center align-items-center"
              style="margin-top: 30px"
            >
            <b-button @click="showPopup = true"> Sign Up </b-button>
            </b-row>
          </b-container>
        </b-row>
      </b-container>
      <!-- Modal Popup -->
      <div v-if="showPopup" class="popup-overlay">
        <div class="popup-content">
          <h2>Contact Information</h2>
          <form @submit.prevent="submitForm">
            <div class="form-group">
              <label for="firstName">First Name:</label>
              <input
                type="text"
                id="firstName"
                placeholder="First Name"
                v-model="formData.firstName"
                required
              />
            </div>
            <div class="form-group">
              <label for="lastName">Last Name:</label>
              <input
                type="text"
                id="lastName"
                placeholder="Last Name"
                v-model="formData.lastName"
                required
              />
            </div>
            <div class="form-group">
              <label for="email">Email Address:</label>
              <input
                type="email"
                id="email"
                placeholder="Email"
                v-model="formData.email"
                required
              />
            </div>
            <div class="form-actions">
              <button
                type="button"
                @click="showPopup = false"
                class="cancel-btn"
              >
                Cancel
              </button>
              <button type="submit" class="submit-btn">Submit</button>
            </div>
          </form>
        </div>
      </div>

      <!-- Success Message Popup -->
      <div v-if="showSuccessMessage" class="popup-overlay">
        <div class="success-popup">
          <h2>Thank You!</h2>
          <p style="color:#333">We will contact you shortly.</p>
          <button @click="showSuccessMessage = false" class="close-btn">
            Close
          </button>
        </div>
      </div>
    </b-jumbotron>
  </div>
</template>
<script>
import emailjs from "emailjs-com";

export default {
  name: "LaunchBanner",
  data() {
    return {
      showPopup: false,
      showSuccessMessage: false,
      formData: {
        firstName: "",
        lastName: "",
        email: "",
      },
      // Replace these with your EmailJS service details
      emailjsConfig: {
        serviceId: "VUE_APP_EMAILJS_SERVICE_ID",
        templateId: "VUE_APP_EMAILJS_TEMPLATE_LAUNCH_ID",
        VUE_APP_EMAILJS_UID: "VUE_EMAILJS_UID",
      },
    };
  },
  methods: {
    submitForm() {
      // Prepare template parameters for EmailJS
      const templateParams = {
        firstName: this.formData.firstName,
        lastName: this.formData.lastName,
        email: this.formData.email,
        // Add any other parameters your EmailJS template requires
      };

      // Send email using EmailJS
      emailjs
        .send(
          process.env.VUE_APP_EMAILJS_SERVICE_ID,
            process.env.VUE_APP_EMAILJS_TEMPLATE_LAUNCH_ID,
            templateParams,
            process.env.VUE_APP_EMAILJS_UID
        )
        .then(() => {
          // Show success message
          this.showPopup = false;
          this.showSuccessMessage = true;

          // Reset form data
          this.formData = {
            firstName: "",
            lastName: "",
            email: "",
          };
        })
        .catch((error) => {
          console.error("EmailJS Error:", error);
          alert(
            "There was an error sending your message. Please try again later."
          );
        });
    },
  },
};
</script>
<style scoped>
.launchBox {
  /* border: 3px solid yellow;
  border-radius: 3px; */
  width: 75%;
}

.desc {
  padding: 0;
  text-align: left;
  margin: auto;
  font-size: 32px;
  font-family: "Anton";
  font-stretch: expanded;
}
.subDesc {
  text-align: left;
  margin: auto auto 10px auto;
  color: #a4a4a4;
  font-size: 20px;
}
.price {
  color: #d8e030;
  font-size: 3rem;
  font-weight: bold;
  rotate: -10deg;
  margin: auto;
  font-family: "Anton";
}
.launchSignUpBtn {
  width: 180px;
}
.launch_info {
  width: 60%;
  margin-left: 2%;
}
.launch_header {
  background-image: url("../assets/img_launch1.png");
  display: flex;
  align-items: center;
  box-shadow: inset 20px -1px 16px 20px #000000cf;
  max-width: 1200px;
  margin: auto;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content,
.success-popup {
  background-color: white;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 100%;
  max-width: 500px;
}

.success-popup {
  text-align: center;
  max-width: 400px;
}

h2 {
  margin-top: 0;
  color: #333;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  box-sizing: border-box;
}

.form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 30px;
}

.submit-btn,
.close-btn {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.submit-btn {
  background-color: darkcyan;
  color: white;
}

.submit-btn:hover {
  background-color: #8eb6c7;
}

.cancel-btn {
  background-color: #f44336;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.cancel-btn:hover {
  background-color: #d32f2f;
}

.close-btn {
  background-color: darkcyan;
  color: white;
}

.close-btn:hover {
  background-color: #8eb6c7;
}

@media (max-width: 610px) {
  .launchBox {
    width: 90%;
  }
  .launch_info {
    width: 98%;
    margin-left: 5px;
  }
  .ttl {
    font-size: 2.5rem;
  }
}
</style>
