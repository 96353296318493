<template>
  <b-jumbotron class="jumboDark">
    <b-container>
      <b-row class="justify-content-center" style="margin-bottom: 30px">
        <b-col cols="12" style="margin-top: 30px">
          <div :style="bgStyle"></div>
        </b-col>
      </b-row>
    </b-container>
    <b-container>
      <b-row class="justify-content-center">
        <b-col cols="12">
          <h1 class="ttl">
            <span style="color: #8eb6c7; font-weight: bold">{{
              titleWord1
            }}</span>
            <span style="color: #d8e030"> {{ titleWord2 }}</span>
          </h1>
        </b-col>
      </b-row>
      <b-row class="justify-content-center" style="margin-bottom: 60px">
        <b-col cols="8">
          <p style="text-align: center; font-size: larger">{{ description }}</p>
        </b-col>
      </b-row>
    </b-container>
  </b-jumbotron>
</template>
  
  <script>
export default {
  name: "CustomJumbotron",
  props: {
    imageUrl: {
      type: String,
      required: true,
    },
    titleWord1: {
      type: String,
      required: true,
    },
    titleWord2: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
},
computed: {
    bgStyle() {
      return {
        backgroundImage: `url(${require(`@/assets/${this.imageUrl}.png`)})`,
        backgroundSize: 'cover',
        backgroundPosition: 'top center',
        backgroundRepeat: 'no-repeat',
        height: '400px',
        boxShadow: 'inset 11px -1px 16px 20px #000000cf'
      };
    }
  },

};
</script>
  
<style scoped>
</style>
  